const isAllowed = true;

let done = false;
let proactiveSessionInit = false;

export function initChat(callback) {
  if (!window.brandembassy) {
    if (!isAllowed) {
      return;
    }

    (function (n, u) {
      window.BrandEmbassy = n;
      window[n] =
        window[n] ||
        function () {
          (window[n].q = window[n].q || []).push(arguments);
        };
      window[n].u = u;
      const e = document.createElement('script');
      e.async = true;
      e.src = `${u}?${Math.round(Date.now() / 1e3 / 3600)}`;
      document.head.appendChild(e);
      e.onload = () => {
        if (!done && (!window.readyState || window.readyState === 'loaded' || window.readyState === 'complete')) {
          console.log('Chat: Init');
          window.brandembassy('init', 4273, 'chat_144b60d3-25ff-4b47-9c97-7f727c2f9e95');

          hideChatButton();

          const btX = setInterval(() => {
            const btn = document.getElementById('be-chat-container');
            console.log('Chat: Button');
            hideChatButton();

            if (btn) {
              clearInterval(btX);
              // chatCtrl(pathname);
              window.brandembassy('setAllowedExternalMessageTypes', ['MESSAGE_RECEIVED']);
              done = true;
              hideChatButton();

              if (callback) {
                callback();
              }
            }
          }, 300);
        }
      };
      e.addEventListener('onreadystatechange', e.onload);
    })('brandembassy', 'https://livechat-static-de-na1.niceincontact.com/4/chat.js');
    hideChatButton();
  }
}

export const setChatEmail = (email) => {
  if (!done) {
    return false;
  }
  try {
    console.log('Setting chat email', email);
    // window.brandembassy('setCustomerEmail', email);
  } catch (e) {
    console.log('Error setting chat email', e);
    return false;
  }
  return true;
};

export const setChatUsername = (username) => {
  if (!done) {
    return false;
  }

  try {
    window.brandembassy('setCustomerName', username);
  } catch (e) {
    console.log('Error setting setCustomerName', e);
    return false;
  }
  return true;
};

export const setChatNameEmail = (email, username) => {
  if (!done) {
    return false;
  }
  setChatEmail(email);
  setChatUsername(username);
  return true;
};

export function hideChatWindow() {
  window.brandembassy('hideChatWindow');
}

export function openChatWindow() {
  showChatButton();
  window.brandembassy('openChatWindow');
}

export const hideChatButton = () => {
  if (!done) {
    return false;
  }

  try {
    hideChatWindow();
    const btn = document.getElementById('be-chat-container');
    if (btn) {
      btn.style.display = 'none';
    }
  } catch (e) {
    console.log('Error setting hideChatButton', e);
    return false;
  }
  return true;
};

export function showChatButton() {
  if (!done) {
    return false;
  }
  try {
    const btn = document.getElementById('be-chat-container');
    if (btn) {
      btn.style.display = 'block';
    }
  } catch (e) {
    console.log('Error setting showChatButton', e);
    return false;
  }
  return true;
}

export function openProActiveChat(first_name) {
  if (!done) {
    return false;
  }

  const result = openProActiveChatCtrl(first_name);
  if (!result) {
    setTimeout(() => {
      openProActiveChatCtrl(first_name);
    }, 500);
  }
  return true;
}

let proactiveChatAttemptNo = 0;
export function openProActiveChatCtrl(first_name) {
  if (!done) {
    return false;
  }

  proactiveChatAttemptNo++;
  console.log('proactiveChatAttemptNo', proactiveChatAttemptNo);
  if (done && window.brandembassy) {
    const res = setChatUsername(first_name);
    if (!res) {
      return false;
    }
    try {
      window.brandembassy('hidePreSurvey');
    } catch (e) {
      console.log('Error hiding pre survey', e);
      return false;
    }

    try {
      if (!proactiveSessionInit) {
        proactiveSessionInit = true;
        window.brandembassy('sendFirstMessageAutomatically', 'New conversation');
        window.brandembassy('autoStartSession');
        hideChatWindow();
      } else {
        openChatWindow();
        console.log('Open pro-active chat. Should start test session.');
      }
    } catch (e) {
      console.log('Error open pro-active chat', e);
      return false;
    }
    return true;
  }
  return false;
}

// export function chatCtrl(pathname) {
//   if (pathname.indexOf('/meet/') > -1) {
//     if ([ROUTES.MEET_MEETING_ROOM.link, ROUTES.MEET_TESTIMONIAL.link].includes(pathname)) {
//       hideChatButton();
//     } else {
//       console.log('Chat: Show button');
//       showChatButton();
//     }
//   } else {
//     hideChatButton();
//   }
// }

export const setFullScreenUI = () => {
  window.brandembassy('setFullDisplay');
  window.brandembassy(
    'setCustomCss',
    '[data-cy="header-minimize-window"] { display: none} [data-selector="HEADER"] { border-radius: 0 !important}'
  );
};
