import { ROUTES } from '@/_navigation/routes';

import CATEGORIES from './category-mapper';

const bridgeData = [
  {
    [ROUTES.QUALIFY.link]: {
      lightLogo: true,
      nextPage: ROUTES.MINDSET_Q1.link,
      main: true,
      hideBackButton: true,
      informative: true,
      bubbleChat: false,
      header: `{name}, our Bridge Program helps you work towards  your RN in <div class="i">less time</div>.`,
      subheader: `Let’s see how it will work <div class="i">for you</div>.`,
      buttonText: 'Next',
      backgroundColor: 'bg-primary',
      defaultProgress: true,
    },
    questions: [
      {
        [ROUTES.MINDSET_Q1.link]: {
          nextPage: ROUTES.MINDSET_Q2.link,
          api: 'ATP_1__c',
          informative: false,
          header: `How important is it to finish your RN in <div class="i">less time</div>?`,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          disableButton: false,
          question: true,
          defaultProgress: true,
          category: CATEGORIES.MINDSET_QUALIFY,
          countConditionals: [],
          list: [
            {
              input: 'select',
              options: [
                {
                  option: `Not important.`,
                  score: 15,
                },
                {
                  option: `Somewhat important.`,
                  score: 25,
                },
                {
                  option: `Very important.`,
                  score: 50,
                },
              ],
              conditionals: [
                {
                  value: `Not important.`,
                  redirect: true,
                  page: ROUTES.MINDSET_R_Q2.link,
                },
              ],
              placeholder: '',
            },
          ],
        },
      },
      {
        [ROUTES.MINDSET_Q2.link]: {
          nextPage: ROUTES.MINDSET_INFO1.link,
          api: 'ATP_2__c',
          informative: false,
          header: `What part of your RN path has been frustrating?`,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          question: true,
          defaultProgress: true,
          category: CATEGORIES.MINDSET_QUALIFY,
          countConditionals: [],
          list: [
            {
              input: 'textarea',
              options: [],
              conditionals: [],
              placeholder: 'Enter your answer here...',
            },
          ],
        },
      },
      {
        [ROUTES.MINDSET_INFO1.link]: {
          step: ' ',
          nextPage: ROUTES.MINDSET_Q3.link,
          informative: true,
          bubbleChat: true,
          isDynamic: false,
          defaultProgress: true,
          header: '{name}, did you know...',
          subheader: `Traditional college has failed working adults. Inconvenient schedules and heavy work loads make it challenging for parents and nursing professionals to succeed. <div class="i">Our goal is to ease these barriers for you</div>.`,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
        },
      },
      {
        [ROUTES.MINDSET_Q3.link]: {
          nextPage: ROUTES.MINDSET_INFO2.link,
          api: 'ATP_4__c',
          informative: false,
          defaultProgress: true,
          header: `How would you feel if you could <div class="i">save</div> time while passing college courses?`,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          question: true,
          allowCategoryAndUpload: true,
          category: CATEGORIES.MINDSET_QUALIFY,
          countConditionals: [],
          list: [
            {
              input: 'select',
              options: [
                {
                  option: `I wouldn't care.`,
                  score: 15,
                },
                {
                  option: `Somewhat excited.`,
                  score: 25,
                },
                {
                  option: `Very excited.`,
                  score: 50,
                },
              ],
              conditionals: [],
              placeholder: '',
            },
          ],
        },
      },
      {
        [ROUTES.MINDSET_INFO2.link]: {
          step: ' ',
          nextPage: ROUTES.MINDSET_VIDEO.link,
          informative: true,
          bubbleChat: true,
          isDynamic: false,
          defaultProgress: true,
          header: `{name}, you've come to the right place! 😊`,
          subheader: `Achieve can help you reach your RN career goals, no matter where you're starting from. <div class="i">Let's see how our Bridge Program works.</div>`,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
        },
      },
      {
        [ROUTES.MINDSET_VIDEO.link]: {
          nextPage: ROUTES.BRIDGE_START.link,
          informative: true,
          bubbleChat: true,
          isDynamic: false,
          header: `Watch how our RN Bridge Program <div class="i">fits into your life</div>.`,
          subheader: `<div class="i">Real</div> words from <div class="i">real</div> customers.`,
          videoComponent: true,
          videoLink:
            'https://player.vimeo.com/progressive_redirect/playback/723467790/rendition/1080p/file.mp4?loc=external&signature=65528090112f62f101c6167a93ddc1bdd44657d17d0054ca934795fc3dd94df8',
          hideFooter: false,
          step: 'Did you know...',
          defaultProgress: true,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-x-medium',
          disableButton: false,
        },
      },
      {
        [ROUTES.MINDSET_R_Q2.link]: {
          nextPage: ROUTES.MINDSET_R_END.link,
          api: 'ATP_Reject__c',
          informative: false,
          bubbleChat: true,
          header: '{name}, can you tell us why stress and time aren’t concerns for you?',
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          question: true,
          defaultProgress: true,
          allowCategoryAndUpload: true,
          category: CATEGORIES.MINDSET_REPATH_NOT_READY,
          list: [
            {
              input: 'textarea',
              options: [],
              conditionals: [],
              placeholder: 'Enter your answer here...',
            },
          ],
        },
      },
      {
        [ROUTES.MINDSET_R_END.link]: {
          nextPage: '',
          informative: true,
          bubbleChat: true,
          hidePageCount: true,
          isDynamic: false,
          customKey: 'rejectInfo',
          header: `{name}, thanks for helping us understand. `,
          subheader: `Please keep Achieve Test Prep in mind if your circumstances change.`,
          info: `Do you want to take your Nursing career to the next level, but don't want to attend school full-time?`,
          infoLink: 'https://www.achievetestprep.com/blog/why-a-fast-track-nursing-program',
          fullHeight: true,
          defaultProgress: true,
          hideFooter: true,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
        },
      },
    ],
  },

  {
    [ROUTES.BRIDGE_START.link]: {
      lightLogo: true,
      nextPage: ROUTES.BRIDGE_GEN_ED.link,
      informative: true,
      bubbleChat: false,
      header: `{name}, we want to know where you’re at in your education journey.`,
      subheader: `Let's explore how we can help you save <div class="i">time</div> and <div class="i">effort</div> while passing your general education courses.`,
      buttonText: 'Next',
      hidePageCount: true,
      backgroundColor: 'bg-primary',
      defaultProgress: true,
      hideBackButton: true,
      main: true,
    },
    questions: [
      {
        [ROUTES.BRIDGE_GEN_ED_INFO.link]: {
          nextPage: ROUTES.BRIDGE_GEN_ED_Q1.link,
          informative: true,
          bubbleChat: false,
          isDynamic: false,
          step: ' ',
          disableButton: false,
          customBody: 'gen ed table',
          header: ``,
          subheader: `RNs must complete their Gen Eds as part of the nursing degree process. We can help determine which of your previous college credits will transfer.`,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          defaultProgress: true,
        },
      },
    ],
  },

  {
    [ROUTES.BRIDGE_GEN_ED.link]: {
      nextPage: ROUTES.BRIDGE_GEN_ED_INFO.link,
      informative: true,
      bubbleChat: false,
      main: true,
      step: 'Bridge 1/4',
      header: 'General Education',
      subheader: `Accredited nursing colleges require several General Education (Gen Ed) or Prerequisite courses before you can start your core RN courses. <div class="i">Let’s take a look at these Gen Eds</div>.`,
      buttonText: 'Next',
      darklogo: true,
      backgroundColor: 'bg-primary-x-medium',
      defaultProgress: true,
      // headerHeight_lg: '20px',
      // headerHeight_xl: '40px',
    },
    questions: [
      {
        [ROUTES.BRIDGE_GEN_ED_Q1.link]: {
          nextPage: ROUTES.BRIDGE_GEN_ED_Q2.link,
          api: 'ATP_5__c',
          informative: false,
          header: 'Have you passed all of your Gen Ed courses?',
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          defaultProgress: true,
          question: true,
          category: CATEGORIES.GENERAL_EDUCATION,
          countConditionals: [
            {
              ATP_4__c: `I've passed all of my Gen Ed courses.`,
              include: ['ATP_16__c', 'ATP_17__c', 'School_Name__c'],
              exclude: ['ATP_6__c'],
            },
          ],
          list: [
            {
              input: 'select',
              options: [
                {
                  option: `I've passed all of my Gen Ed Courses.`,
                  score: 0,
                },
                {
                  option: `I've passed some, but not all of them.`,
                  score: 25,
                },
                {
                  option: `I haven't passed any Gen Eds yet.`,
                  score: 50,
                },
              ],
              conditionals: [
                {
                  value: `I've passed all of my Gen Ed Courses.`,
                  redirect: true,
                  skipQuestion: {
                    api: 'ATP_6__c',
                    value: 'Not important.',
                  },
                  page: ROUTES.BRIDGE_GEN_ED_END_PASSED_END.link,
                },
              ],
              placeholder: '',
            },
          ],
        },
      },
      {
        [ROUTES.BRIDGE_GEN_ED_Q2.link]: {
          nextPage: ROUTES.BRIDGE_GEN_ED_END.link,
          api: 'ATP_6__c',
          informative: false,
          header: `How important is it to finish your remaining Gen Ed courses <div class="i"> in less time</div>?`,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          defaultProgress: true,
          question: true,
          category: CATEGORIES.GENERAL_EDUCATION,
          list: [
            {
              input: 'select',
              options: [
                {
                  option: `Not important.`,
                  score: 10,
                },
                {
                  option: `Somewhat important.`,
                  score: 25,
                },
                {
                  option: `Very important.`,
                  score: 50,
                },
              ],
              conditionals: [
                {
                  value: 'Not important',
                  redirect: true,
                  page: '1_reject',
                },
              ],
              placeholder: '',
            },
          ],
        },
      },
      {
        [ROUTES.BRIDGE_GEN_ED_END.link]: {
          nextPage: ROUTES.BRIDGE_ENTRANCE_EXAM.link,
          informative: true,
          bubbleChat: true,
          step: ' ',
          isDynamic: false,
          header: `Great, {name}! We can help. Let’s keep going...`,
          subheader: `Gen Ed credits typically take two years in traditional college, but our Bridge Plan can fast track your courses without compromising your schedule. <div class="i">Now, let’s take a look at your RN Entrance Exam</div>.`,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          defaultProgress: true,
          allowCategoryAndUpload: true,
          category: CATEGORIES.GENERAL_EDUCATION,
        },
      },
      {
        [ROUTES.BRIDGE_GEN_ED_END_PASSED_END.link]: {
          nextPage: ROUTES.BRIDGE_GEN_ED_END.link,
          informative: true,
          bubbleChat: true,
          isDynamic: false,
          step: ' ',
          header: `That's outstanding, {name}! Let's keep going...`,
          subheader: `Excellent job on passing all of your required Gen Ed courses - we know it's not easy. <div class="i">Now, let's move forward with the RN Entrance Exam...</div>`,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          defaultProgress: true,
          allowCategoryAndUpload: true,
          category: CATEGORIES.GEN_ED_REPATH_PASSED_ALL,
        },
      },
    ],
  },

  {
    [ROUTES.BRIDGE_ENTRANCE_EXAM.link]: {
      nextPage: ROUTES.BRIDGE_ENTRANCE_EXAM_Q1.link,
      informative: true,
      bubbleChat: false,
      main: true,
      header: 'RN Entrance Exam',
      step: 'Bridge 2/4',
      subheader: `Before you can get admitted to an accredited nursing school, you will be required to pass one of the RN Entrance Exams <span class="font-bold contents">(TEAS, HESI, PAX, KAPLAN)</span>. <div class="i">Let’s see what type of support you may need…</div>`,
      buttonText: 'Next',
      darklogo: true,
      backgroundColor: 'bg-primary-x-medium',
      defaultProgress: true,
      // headerHeight_lg: '20px',
      // headerHeight_xl: '40px',
    },
    questions: [
      {
        [ROUTES.BRIDGE_ENTRANCE_EXAM_Q1.link]: {
          nextPage: ROUTES.BRIDGE_ENTRANCE_EXAM_INFO.link,
          api: 'ATP_7__c',
          informative: false,
          header: 'Have you taken and passed your RN Entrance Exam?',
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          defaultProgress: true,
          question: true,
          category: CATEGORIES.ENTRANCE_EXAM,
          countConditionals: [],
          list: [
            {
              input: 'select',
              options: [
                {
                  option: `I’ve passed my RN entrance exam.`,
                  score: 0,
                },
                {
                  option: `I took the exam, but didn't pass.`,
                  score: 50,
                },
                {
                  option: `I haven’t taken my RN entrance exam.`,
                  score: 50,
                },
              ],
              conditionals: [
                {
                  value: `I’ve passed my RN entrance exam.`,
                  redirect: true,
                  skipQuestion: {
                    api: 'ATP_8__c',
                    value: 'Not concerned.',
                  },
                  page: ROUTES.BRIDGE_ENTRANCE_EXAM_PASSED_END.link,
                },
              ],
              placeholder: '',
            },
          ],
        },
      },
      {
        [ROUTES.BRIDGE_ENTRANCE_EXAM_INFO.link]: {
          nextPage: ROUTES.BRIDGE_ENTRANCE_EXAM_Q2.link,
          informative: true,
          bubbleChat: true,
          isDynamic: false,
          step: ' ',
          header: `{name}, Achieve offers Entrance Exam support.`,
          subheader: `Each nursing college sets their own requirements on how many times you can take an RN Entrance Exam, with additional restrictions on how long you have to wait in between each attempt. <div class="i">How would you like to avoid delays and pass on the first attempt</div>?`,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          defaultProgress: true,
        },
      },
      {
        [ROUTES.BRIDGE_ENTRANCE_EXAM_Q2.link]: {
          nextPage: ROUTES.BRIDGE_ENTRANCE_EXAM_END.link,
          api: 'ATP_8__c',
          informative: false,
          header: 'How concerned are you about your ability to pass your RN Entrance Exam?',
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          defaultProgress: true,
          question: true,
          category: CATEGORIES.ENTRANCE_EXAM,
          countConditionals: [],
          list: [
            {
              input: 'select',
              options: [
                {
                  option: `Not concerned.`,
                  score: 10,
                },
                {
                  option: `Somewhat concerned.`,
                  score: 25,
                },
                {
                  option: `Very concerned.`,
                  score: 50,
                },
              ],
              conditionals: [],
              placeholder: '',
            },
          ],
        },
      },
      {
        [ROUTES.BRIDGE_ENTRANCE_EXAM_END.link]: {
          nextPage: ROUTES.BRIDGE_CORE_NURSING.link,
          informative: true,
          bubbleChat: true,
          isDynamic: false,
          step: ' ',
          header: `{name}, we can help you reach this goal.`,
          subheader: `After Gen Eds and the RN Entrance Exam, your core RN courses are next. <div class="i">Let’s see what type of support you may need for your core RN courses...</div>`,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          allowCategoryAndUpload: true,
          category: CATEGORIES.ENTRANCE_EXAM,
          defaultProgress: true,
        },
      },
      {
        [ROUTES.BRIDGE_ENTRANCE_EXAM_PASSED_END.link]: {
          nextPage: ROUTES.BRIDGE_ENTRANCE_EXAM_END.link,
          informative: true,
          bubbleChat: true,
          isDynamic: false,
          step: 'Bridge 2/4',
          header: `Way to go, {name}! Let's move forward...`,
          subheader: `Now, let’s see what type of guidance and support you may need for your core RN courses.`,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          allowCategoryAndUpload: true,
          category: CATEGORIES.REPATH_PASSED_ENTRANCE_EXAM,
          defaultProgress: true,
        },
      },
    ],
  },

  {
    [ROUTES.BRIDGE_CORE_NURSING.link]: {
      nextPage: ROUTES.BRIDGE_CORE_NURSING_Q1.link,
      informative: true,
      main: true,
      bubbleChat: false,
      header: CATEGORIES.CORE_RN_COURSES,
      step: 'Bridge 3/4',
      subheader: `We offer support throughout your education journey, including core RN courses taken through your accredited college or university of choice.`,
      buttonText: 'Next',
      darklogo: true,
      backgroundColor: 'bg-primary-x-medium',
      defaultProgress: true,
      // headerHeight_lg: '20px',
      // headerHeight_xl: '40px',
    },
    questions: [
      {
        [ROUTES.BRIDGE_CORE_NURSING_Q1.link]: {
          nextPage: ROUTES.BRIDGE_CORE_NURSING_Q2.link,
          api: 'ATP_9__c',
          informative: false,
          header: 'Have you taken and passed your core RN courses?',
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          defaultProgress: true,
          question: true,
          category: CATEGORIES.CORE_RN_COURSES,
          countConditionals: [],
          list: [
            {
              input: 'select',
              options: [
                {
                  option: `I’ve passed my core RN courses.`,
                  score: 0,
                },
                {
                  option: `I've passed some, but not all of them.`,
                  score: 25,
                },
                {
                  option: `I haven’t taken my core RN courses.`,
                  score: 50,
                },
              ],
              conditionals: [
                {
                  value: `I’ve passed my core RN courses.`,
                  redirect: true,
                  skipQuestion: {
                    api: 'ATP_10__c',
                    value: 'Not concerned.',
                  },
                  page: ROUTES.BRIDGE_CORE_NURSING_PASSED_END.link,
                },
              ],
              placeholder: '',
            },
          ],
        },
      },
      {
        [ROUTES.BRIDGE_CORE_NURSING_INFO.link]: {
          nextPage: ROUTES.BRIDGE_CORE_NURSING_END.link,
          informative: true,
          bubbleChat: true,
          step: ' ',
          isDynamic: false,
          header: `{name}, we offer RN course tutoring support.`,
          subheader: `As you take your core RN courses through your accredited college, we offer tutoring support and guidance from experienced BSNs.`,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          defaultProgress: true,
        },
      },
      {
        [ROUTES.BRIDGE_CORE_NURSING_Q2.link]: {
          nextPage: ROUTES.BRIDGE_CORE_NURSING_INFO.link,
          api: 'ATP_10__c',
          informative: false,
          header: 'How concerned are you about your ability to pass each core RN course?',
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          defaultProgress: true,
          question: true,
          category: CATEGORIES.CORE_RN_COURSES,
          countConditionals: [],
          list: [
            {
              input: 'select',
              options: [
                {
                  option: `Not concerned.`,
                  score: 10,
                },
                {
                  option: `Somewhat concerned.`,
                  score: 25,
                },
                {
                  option: `Very concerned.`,
                  score: 50,
                },
              ],
              conditionals: [],
              placeholder: '',
            },
          ],
        },
      },
      {
        [ROUTES.BRIDGE_CORE_NURSING_END.link]: {
          nextPage: ROUTES.BRIDGE_EXIT_EXAM.link,
          informative: true,
          bubbleChat: true,
          isDynamic: false,
          step: ' ',
          header: `Guidance at every step.`,
          subheader: `Our Bridge can help you prepare and pass each one of your core Nursing courses. <div class="i">Now, let’s see how much support you need with the final step of the Bridge, your RN Licensure Exam.</div>`,
          buttonText: 'Next',
          allowCategoryAndUpload: true,
          category: CATEGORIES.CORE_RN_COURSES,
          backgroundColor: 'bg-primary-xx-light',
          defaultProgress: true,
        },
      },
      {
        [ROUTES.BRIDGE_CORE_NURSING_PASSED_END.link]: {
          nextPage: ROUTES.BRIDGE_CORE_NURSING_END.link,
          informative: true,
          bubbleChat: true,
          isDynamic: false,
          step: 'Bridge 3/4',
          header: `Great work, {name}! And now, the final step...`,
          subheader: `Let’s see how much support you need with the final step of the Bridge, your RN Licensure Exam (NCLEX-RN).`,
          buttonText: 'Next',
          allowCategoryAndUpload: true,
          category: CATEGORIES.REPATH_PASSED_CORE_RN_COURSES,
          backgroundColor: 'bg-primary-xx-light',
          defaultProgress: true,
        },
      },
    ],
  },

  {
    [ROUTES.BRIDGE_EXIT_EXAM.link]: {
      nextPage: ROUTES.BRIDGE_EXIT_EXAM_Q1.link,
      informative: true,
      main: true,
      bubbleChat: false,
      step: 'Bridge 4/4',
      header: 'RN Licensure Exam',
      subheader: `The NCLEX-RN is the all-important step between graduating from nursing school and becoming an RN. It’s a standardized test, set by the Board of Nursing in each state, that makes sure you have the knowledge and skills you need to be a Registered Nurse.`,
      buttonText: 'Next',
      darklogo: true,
      backgroundColor: 'bg-primary-x-medium',
      defaultProgress: true,
    },
    questions: [
      {
        [ROUTES.BRIDGE_EXIT_EXAM_Q1.link]: {
          nextPage: ROUTES.BRIDGE_EXIT_EXAM_Q2.link,
          api: 'ATP_11__c',
          informative: false,
          header: 'Have you taken and passed your RN Licensure Exam?',
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          defaultProgress: true,
          question: true,
          category: CATEGORIES.EXIT_EXAM,
          countConditionals: [],
          list: [
            {
              input: 'select',
              options: [
                {
                  option: `I’ve passed the NCLEX-RN.`,
                  score: 0,
                },
                {
                  option: `I took the NCLEX-RN, but didn’t pass it.`,
                  score: 50,
                },
                {
                  option: `I haven’t taken the NCLEX-RN yet.`,
                  score: 50,
                },
              ],
              conditionals: [
                {
                  value: `I’ve passed the NCLEX-RN.`,
                  redirect: true,
                  skipQuestion: {
                    api: 'ATP_12__c',
                    value: 'Not concerned.',
                  },
                  page: ROUTES.BRIDGE_EXIT_EXAM_PASSED_END.link,
                },
              ],
              placeholder: '',
            },
          ],
        },
      },
      {
        [ROUTES.BRIDGE_EXIT_EXAM_INFO.link]: {
          nextPage: ROUTES.BRIDGE_EXIT_EXAM_END.link,
          informative: true,
          bubbleChat: true,
          isDynamic: false,
          step: ' ',
          header: `Cross the finish line with confidence.`,
          subheader: `The NCLEX-RN has between 75 to 145 questions, stopping when your competency level has been identified. Passing the exam on your first attempt will bypass any delays (up to 45 days to retest) in getting you that RN pay.`,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          defaultProgress: true,
        },
      },
      {
        [ROUTES.BRIDGE_EXIT_EXAM_Q2.link]: {
          nextPage: ROUTES.BRIDGE_EXIT_EXAM_INFO.link,
          api: 'ATP_12__c',
          informative: false,
          header: 'How concerned are you about your ability to pass your RN Licensure Exam?',
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          defaultProgress: true,
          question: true,
          category: CATEGORIES.EXIT_EXAM,
          countConditionals: [],
          list: [
            {
              input: 'select',
              options: [
                {
                  option: `Not concerned.`,
                  score: 10,
                },
                {
                  option: `Somewhat concerned.`,
                  score: 25,
                },
                {
                  option: `Very concerned.`,
                  score: 50,
                },
              ],
              conditionals: [],
              placeholder: '',
            },
          ],
        },
      },
      {
        [ROUTES.BRIDGE_EXIT_EXAM_END.link]: {
          nextPage: ROUTES.BRIDGE_CONFIRM_CONTACT.link,
          informative: true,
          bubbleChat: true,
          isDynamic: false,
          step: ' ',
          header: `{name}, we can help. Let’s do this together.`,
          subheader: `The first step is to customize your Bridge Plan, a step-by-step map outlining everything you need for your education journey. <div class="i">Now that we know more about you, let’s move forward and see where Achieve can help...</div>`,
          buttonText: 'Next',
          allowCategoryAndUpload: true,
          category: CATEGORIES.EXIT_EXAM,
          backgroundColor: 'bg-primary-xx-light',
          defaultProgress: true,
        },
      },
      {
        [ROUTES.BRIDGE_EXIT_EXAM_PASSED_END.link]: {
          nextPage: ROUTES.BRIDGE_EXIT_EXAM_PASSED_END_Q1.link,
          informative: true,
          bubbleChat: true,
          isDynamic: false,
          step: '',
          header: `{name}, congrats! You should be proud...`,
          subheader: `Once your scores are official they will be published on your state's Board of Nursing website and you can legally practice as a nurse in your state. We wish you the best with your exciting job hunt. Please share Achieve with your nursing friends and family.`,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          allowCategoryAndUpload: true,
          category: CATEGORIES.REPATH_PASSED_EXIT_EXAM,
          hidePageCount: true,
          defaultProgress: true,
          hideProgress: true,
        },
      },
      {
        [ROUTES.BRIDGE_EXIT_EXAM_PASSED_END_Q1.link]: {
          nextPage: ROUTES.BRIDGE_EXIT_EXAM_PASSED_END_Q1_END.link,
          api: 'ATP_Exit_Reject__c',
          informative: false,
          bubbleChat: true,
          header: '{name}, can you share what you are looking to learn?',
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          question: true,
          hideProgress: true,
          hidePageCount: true,
          defaultProgress: true,
          allowCategoryAndUpload: true,
          list: [
            {
              input: 'textarea',
              options: [],
              conditionals: [],
              placeholder: 'Enter your answer here...',
            },
          ],
        },
      },
      {
        [ROUTES.BRIDGE_EXIT_EXAM_PASSED_END_Q1_END.link]: {
          nextPage: '',
          informative: true,
          bubbleChat: true,
          hidePageCount: true,
          isDynamic: false,
          customKey: 'rejectInfo',
          header: `{name}, thanks for helping us understand. `,
          subheader: `Please keep Achieve Test Prep in mind if you have nursing friends and family interested in pursuing their RN program with less stress and time.`,
          info: `Want to see your NCLEX results and see what you should expect in the following weeks?`,
          infoLink: 'https://www.nclex.com/quick-results.page',
          fullHeight: true,
          defaultProgress: true,
          hideFooter: true,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
        },
      },
      {
        [ROUTES.BRIDGE_CONFIRM_CONTACT.link]: {
          nextPage: ROUTES.BRIDGE_RESULTS.link,
          api: 'contact_form',
          informative: false,
          bubbleChat: false,
          step: '',
          hidePageCount: true,
          header: 'Confirm your contact info to get started.',
          subheader: ``,
          buttonText: 'Next',
          isSubmission: true,
          backgroundColor: 'bg-primary-xx-light',
          defaultProgress: true,
          category: CATEGORIES.RESULT_QUALIFY,
          list: [
            {
              input: 'contact_form',
              options: [],
              conditionals: [],
              placeholder: '',
            },
          ],
        },
      },
      {
        [ROUTES.BRIDGE_RESULTS.link]: {
          nextPage: ROUTES.BRIDGE_PLAN_NEXT_STEP.link,
          informative: true,
          question: false,
          bubbleChat: false,
          isDynamic: false,
          results: true,
          defaultProgress: true,
          darklogo: true,
          header: `{name}, you've qualified to get a Bridge Plan!`,
          hideProgress: true,
          hideBackButton: false,
          hidePageCount: true,
          subheader: `Your Bridge Plan will explain how to fast track your RN degree.`,
          buttonText: 'Next',
          backgroundColor: 'bg-white',
          boxShadowColor: '1px 1px 7px 3px rgba(209, 148, 217, 0.2)',
          buttonColor: 'bg-primary',
          footerColor: 'bg-white',
          category: CATEGORIES.RESULT_QUALIFY,
        },
      },
      {
        [ROUTES.BRIDGE_PLAN_NEXT_STEP.link]: {
          nextPage: ROUTES.BRIDGE_PLAN_ADVISOR.link,
          informative: false,
          bubbleChat: false,
          isDynamic: false,
          pageCard: true,
          customKey: 'planFinishFaster',
          fullHeight: true,
          header: ``,
          subheader: ``,
          buttonText: 'Next',
          hideButtonIcon: false,
          hidePageCount: true,
          defaultProgress: true,
          hideFooter: true,
          backgroundColor: 'bg-primary-xx-light',
          buttonColor: 'bg-primary',
        },
      },
      {
        [ROUTES.BRIDGE_PLAN_ADVISOR.link]: {
          nextPage: ROUTES.BRIDGE_PLAN_ADVISOR_PLAN.link,
          informative: false,
          bubbleChat: false,
          isDynamic: false,
          pageCard: true,
          customKey: 'planWithExperts',
          fullHeight: true,
          header: ``,
          subheader: ``,
          buttonText: 'Next',
          hideButtonIcon: false,
          hidePageCount: true,
          defaultProgress: true,
          hideFooter: true,
          backgroundColor: 'bg-primary-xx-light',
          buttonColor: 'bg-primary',
        },
      },
      {
        [ROUTES.BRIDGE_PLAN_ADVISOR_PLAN.link]: {
          nextPage: ROUTES.BRIDGE_PLAN_WHY_PLAN.link,
          informative: false,
          bubbleChat: false,
          isDynamic: false,
          pageCard: true,
          customKey: 'youAndYourAdvisor',
          fullHeight: true,
          header: ``,
          subheader: ``,
          buttonText: 'Next',
          hideButtonIcon: false,
          hidePageCount: true,
          defaultProgress: true,
          hideFooter: true,
          backgroundColor: 'bg-primary-xx-light',
          buttonColor: 'bg-primary',
        },
      },
      {
        [ROUTES.BRIDGE_PLAN_WHY_PLAN.link]: {
          nextPage: ROUTES.BRIDGE_PLAN_PRE_BUY.link,
          informative: false,
          bubbleChat: false,
          isDynamic: false,
          pageCard: true,
          customKey: 'whyShouldBridge',
          fullHeight: true,
          header: ``,
          subheader: ``,
          buttonText: 'Next',
          hideButtonIcon: false,
          hidePageCount: true,
          defaultProgress: true,
          hideFooter: true,
          backgroundColor: 'bg-primary-xx-light',
          buttonColor: 'bg-primary',
          category: CATEGORIES.BP_INFO,
        },
      },
      {
        [ROUTES.BRIDGE_PLAN_PRE_BUY.link]: {
          nextPage: ROUTES.BRIDGE_PLAN_PRE_BUY_CHECKOUT.link,
          informative: false,
          bubbleChat: false,
          isDynamic: false,
          pageCard: true,
          customKey: 'prebuyyourbridge',
          fullHeight: true,
          header: ``,
          subheader: ``,
          buttonText: 'Start My Plan',
          hideButtonIcon: true,
          hidePageCount: true,
          defaultProgress: true,
          hideFooter: true,
          backgroundColor: 'bg-primary-xx-light',
          buttonColor: 'bg-primary',
          category: CATEGORIES.BP_PRE_BUY,
        },
      },
      /** New Question Page */
      {
        [ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY.link]: {
          nextPage: '',
          api: 'ATP_16__c',
          customKey: 'WhenToMeet',
          informative: false,
          hidePageCount: true,
          hideProgress: true,
          header: 'How quickly do you want to start your bridge program?',
          question: true,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          defaultProgress: true,
          hideButton: false,
          meetAction: true,
          allowCategoryAndUpload: true,
          category: CATEGORIES.READINESS,
          list: [
            {
              input: 'select',
              options: [
                {
                  option: "<strong>Immediately.</strong> I'm ready to select a plan.",
                  answerTestKey: "Immediately. I'm ready to select a plan.",
                  timeSensitive: true,
                },
                {
                  option: '<strong>Soon.</strong> I’m ready to discuss my RN goals.',
                  answerTestKey: 'Soon. I’m ready to discuss my RN goals.',
                },
                {
                  option: "<strong>Not yet.</strong> I'd like more information.",
                  answerTestKey: "Not yet. I'd like more information.",
                },
              ],
              conditionals: [
                {
                  redirect: true,
                  value: "Immediately. I'm ready to select a plan.",
                  category: CATEGORIES.READY_IMMEDIATE_RES_MEET,
                  offOfficeHoursCategory: CATEGORIES.READY_IMMEDIATE_NON_BUSINESS_HOURS,
                },
                {
                  page: ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_SOON_RES_SCHEDULED.link,
                  redirect: true,
                  value: 'Soon. I’m ready to discuss my RN goals.',
                  category: CATEGORIES.READY_SOON_RES_SCHEDULE,
                },
                {
                  page: ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_NOT_YET_VIDEO.link,
                  redirect: true,
                  value: "Not yet. I'd like more information.",
                },
              ],
              placeholder: '',
            },
          ],
        },
      },
      {
        [ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_IMMEDIATE_CONNECT.link]: {
          nextPage: ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_IMMEDIATE_RES_SCHEDULED.link,
          informative: false,
          bubbleChat: false,
          isDynamic: false,
          pageCard: true,
          customKey: 'immediateCallChat',
          fullHeight: true,
          header: ``,
          subheader: ``,
          buttonText: "Let's Chat",
          hideButtonIcon: false,
          hidePageCount: true,
          defaultProgress: true,
          hideFooter: true,
          backgroundColor: 'bg-primary-xx-light',
          buttonColor: 'bg-primary',
          meetAction: true,
          category: CATEGORIES.READY_IMMEDIATE_CALL_LAUNCH,
          secondaryCategory: CATEGORIES.READY_IMMEDIATE_RES_SCHEDULED,
          tertiaryCategory: CATEGORIES.READY_IMMEDIATE_CHAT_LAUNCH,
        },
        questions: [],
      },
      // {
      //   [ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_IMMEDIATE_CHAT.link]: {
      //     nextPage: ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_IMMEDIATE_RES_SCHEDULED.link,
      //     informative: false,
      //     bubbleChat: false,
      //     isDynamic: false,
      //     pageCard: true,
      //     customKey: 'immediateCallChat',
      //     fullHeight: true,
      //     header: ``,
      //     subheader: ``,
      //     buttonText: 'Start Call Now',
      //     hideButtonIcon: false,
      //     hidePageCount: true,
      //     defaultProgress: true,
      //     hideFooter: true,
      //     backgroundColor: 'bg-primary-xx-light',
      //     buttonColor: 'bg-primary',
      //     meetAction: true,
      //     category: CATEGORIES.READY_IMMEDIATE_NUMBER_COPIED,
      //     secondaryCategory: CATEGORIES.READY_IMMEDIATE_RES_SCHEDULED,
      //   },
      //   questions: [],
      // },
      {
        [ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_IMMEDIATE_INFO.link]: {
          nextPage: ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_IMMEDIATE_RES_SCHEDULED.link,
          informative: false,
          bubbleChat: false,
          isDynamic: false,
          pageCard: true,
          customKey: 'immediateReservation',
          fullHeight: true,
          header: ``,
          subheader: ``,
          buttonText: 'Schedule Now',
          hideButtonIcon: false,
          hidePageCount: true,
          defaultProgress: true,
          hideFooter: true,
          backgroundColor: 'bg-primary-xx-light',
          buttonColor: 'bg-primary',
          meetAction: true,
          category: CATEGORIES.READY_IMMEDIATE_RES_SCHEDULED,
          secondaryCategory: CATEGORIES.READY_IMMEDIATE_LEARN,
        },
        questions: [],
      },
      {
        [ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_SOON_RES_SCHEDULED.link]: {
          informative: true,
          bubbleChat: true,
          isDynamic: false,
          hidePageCount: true,
          hideProgress: true,
          fullHeight: true,
          calendlyComponent: true,
          hideFooter: true,
          step: '',
          defaultProgress: true,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-x-medium',
          disableButton: true,
          allowCategoryAndUpload: true,
          category: CATEGORIES.READY_SOON_RES_CONFIRMED,
          nextCategory: CATEGORIES.READY_SOON_RES_CONFIRMED,
        },
      },
      {
        [ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_NOT_YET_RES_SCHEDULED.link]: {
          informative: true,
          bubbleChat: true,
          isDynamic: false,
          hidePageCount: true,
          hideProgress: true,
          fullHeight: true,
          calendlyComponent: true,
          hideFooter: true,
          step: '',
          defaultProgress: true,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-x-medium',
          disableButton: true,
          allowCategoryAndUpload: true,
          category: CATEGORIES.READY_NOT_YET_RES_CONFIRMED,
          nextCategory: CATEGORIES.READY_NOT_YET_RES_CONFIRMED,
        },
      },
      {
        [ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_NOT_YET_VIDEO.link]: {
          nextPage: ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_NOT_YET_INFO.link,
          informative: true,
          bubbleChat: true,
          isDynamic: false,
          hidePageCount: true,
          hideProgress: true,
          header: `Helping you save time while passing college courses`,
          subheader: `No matter where you are in your education journey, <div class="i">Achieve has options for you.</div>`,
          videoComponent: true,
          videoLink:
            'https://player.vimeo.com/progressive_redirect/playback/723467528/rendition/1080p/file.mp4?loc=external&signature=ffb3503369611bc7ec0f193826555f18ab36c06cdb833f20943b178913a585f7',
          hideFooter: false,
          step: '',
          defaultProgress: true,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-x-medium',
          disableButton: false,
          allowCategoryAndUpload: true,
          category: CATEGORIES.READY_NOT_YET_VIDEO,
        },
      },
      {
        [ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_NOT_YET_INFO.link]: {
          nextPage: ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_NOT_YET_OPTS.link,
          informative: true,
          bubbleChat: true,
          hidePageCount: true,
          hideProgress: true,
          step: '',
          isDynamic: false,
          header: `{name}, let’s explore options tailored just for you...`,
          subheader: `Meeting with an Advisor is the best way to get all your questions answered while discovering the right plan for your schedule and budget.`,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          defaultProgress: true,
          category: CATEGORIES.READY_NOT_YET_OPTIONS,
        },
      },
      {
        [ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_NOT_YET_OPTS.link]: {
          api: 'ATP_17__c',
          customKey: 'WoPreBuyWhenToMeet',
          informative: false,
          hidePageCount: true,
          hideProgress: true,
          header: 'Are you <div class="i">ready to discuss options</div> with an Advisor?',
          question: true,
          buttonText: 'Next',
          backgroundColor: 'bg-primary-xx-light',
          defaultProgress: true,
          hideButton: false,
          meetAction: true,
          allowCategoryAndUpload: true,
          list: [
            {
              input: 'select',
              options: [
                {
                  option: "<strong>Yes.</strong> Let's schedule and review options.",
                  answerTestKey: "Yes. Let's schedule and review options.",
                },
                {
                  option: "<strong>Not yet.</strong> I'm still learning.",
                  answerTestKey: "Not yet. I'm still learning.",
                },
              ],
              conditionals: [
                {
                  page: ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_NOT_YET_RES_SCHEDULED.link,
                  redirect: true,
                  value: "Yes. Let's schedule and review options.",
                  category: CATEGORIES.READY_NOT_YET_RES_SCHEDULE,
                },
                {
                  page: 'https://www.achievetestprep.com/program-nursing',
                  redirect: true,
                  redirectLinkType: 'external',
                  openWebWithName: true,
                  value: "Not yet. I'm still learning.",
                  category: CATEGORIES.READY_NOT_YET_LEARN_HOME,
                },
              ],
              placeholder: '',
            },
          ],
        },
      },
      /** New Question Page */
      {
        [ROUTES.BRIDGE_PLAN_PRE_BUY_CHECKOUT.link]: {
          informative: true,
          bubbleChat: false,
          isDynamic: false,
          checkout: true,
          fullHeight: true,
          hideFooter: true,
          header: `Checkout`,
          subheader: ``,
          buttonText: 'Pay Now',
          hideButtonIcon: true,
          defaultProgress: true,
          backgroundColor: 'bg-primary-xx-light',
          buttonColor: 'bg-primary',
        },
      },
    ],
  },

  {
    [ROUTES.BRIDGE_PLAN_PRE_BUY_PURCHASED.link]: {
      lightLogo: true,
      nextPage: ROUTES.BRIDGE_PLAN_PRE_BUY_RES.link,
      informative: true,
      bubbleChat: false,
      header: `Congrats, {name}!<br/>You’ve taken the first step toward building an RN Bridge Plan that <div class="i">fits your life</div>. `,
      step: 'Congrats',
      subheader: ``,
      buttonText: 'Next',
      backgroundColor: 'bg-primary',
      buttonColor: 'bg-primary',
      footerColor: 'bg-white',
      defaultProgress: true,
      hideBackButton: true,
      main: true,
      hideProgress: true,
      category: CATEGORIES.BP_PRE_BUY_PURCHASED,
    },
    questions: [],
  },
  {
    [ROUTES.BRIDGE_PLAN_PRE_BUY_RES.link]: {
      nextPage: 'HAVE_A_MEETING',
      informative: false,
      bubbleChat: false,
      isDynamic: false,
      pageCard: true,
      customKey: 'bpPreBuyReservation',
      fullHeight: true,
      header: ``,
      subheader: ``,
      buttonText: 'Chat now',
      hideButtonIcon: false,
      hidePageCount: true,
      defaultProgress: true,
      hideFooter: true,
      backgroundColor: 'bg-primary-xx-light',
      buttonColor: 'bg-primary',
      meetAction: true,
      category: CATEGORIES.BP_PRE_BUY_RES_MEET,
      secondaryCategory: CATEGORIES.BP_PRE_BUY_RES_SCHEDULE,
      timeSensitive: true,
    },
    questions: [],
  },

  {
    [ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_IMMEDIATE_RES_SCHEDULED.link]: {
      informative: true,
      bubbleChat: true,
      isDynamic: false,
      hidePageCount: true,
      hideProgress: true,
      fullHeight: true,
      calendlyComponent: true,
      hideFooter: true,
      step: '',
      defaultProgress: true,
      buttonText: 'Next',
      backgroundColor: 'bg-primary-x-medium',
      disableButton: true,
      allowCategoryAndUpload: true,

      category: CATEGORIES.READY_IMMEDIATE_RES_CONFIRMED,
      nextCategory: CATEGORIES.READY_IMMEDIATE_RES_CONFIRMED,
    },
    questions: [],
  },
  {
    [ROUTES.BRIDGE_PLAN_PRE_BUY_RES_SCHEDULED.link]: {
      informative: true,
      bubbleChat: true,
      isDynamic: false,
      hidePageCount: true,
      hideProgress: true,
      fullHeight: true,
      calendlyComponent: true,
      hideFooter: true,
      step: '',
      defaultProgress: true,
      buttonText: 'Next',
      backgroundColor: 'bg-primary-x-medium',
      disableButton: true,
      allowCategoryAndUpload: true,
      category: CATEGORIES.BP_PRE_BUY_RES_CONFIRMED,
      nextCategory: CATEGORIES.BP_PRE_BUY_RES_CONFIRMED,
    },
    questions: [],
  },
];

export default bridgeData;

// window.bridgeData = bridgeData;
