export const ROUTES = {
  QUALIFY: {
    link: '/',
  },
  MINDSET_Q1: {
    link: '/mindset-qualify/q1',
  },
  MINDSET_Q2: {
    link: '/mindset-qualify/q2',
  },
  MINDSET_INFO1: {
    link: '/mindset-qualify/info1',
  },
  MINDSET_Q3: {
    link: '/mindset-qualify/q3',
  },
  MINDSET_INFO2: {
    link: '/mindset-qualify/info2',
  },
  MINDSET_VIDEO: {
    link: '/mindset-qualify/video',
  },
  MINDSET_R_Q2: {
    link: '/mindset-qualify/r/q2',
  },
  MINDSET_R_END: {
    link: '/mindset-qualify/r/end',
  },
  BRIDGE_START: {
    link: '/bridge/start',
  },
  BRIDGE_GEN_ED: {
    link: '/bridge/gen-ed',
  },
  BRIDGE_GEN_ED_INFO: {
    link: '/bridge/gen-ed/info',
  },
  BRIDGE_GEN_ED_Q1: {
    link: '/bridge/gen-ed/q1',
  },
  BRIDGE_GEN_ED_Q2: {
    link: '/bridge/gen-ed/q2',
  },
  BRIDGE_GEN_ED_END: {
    link: '/bridge/gen-ed/end',
  },
  BRIDGE_GEN_ED_END_PASSED_END: {
    link: '/bridge/gen-ed/passed-end',
  },
  BRIDGE_ENTRANCE_EXAM: {
    link: '/bridge/entra-exam',
  },
  BRIDGE_ENTRANCE_EXAM_Q1: {
    link: '/bridge/entra-exam/q1',
  },
  BRIDGE_ENTRANCE_EXAM_INFO: {
    link: '/bridge/entra-exam/info',
  },
  BRIDGE_ENTRANCE_EXAM_Q2: {
    link: '/bridge/entra-exam/q2',
  },
  BRIDGE_ENTRANCE_EXAM_END: {
    link: '/bridge/entra-exam/end',
  },
  BRIDGE_ENTRANCE_EXAM_PASSED_END: {
    link: '/bridge/entra-exam/passed-end',
  },
  BRIDGE_CORE_NURSING: {
    link: '/bridge/core-nursing',
  },
  BRIDGE_CORE_NURSING_Q1: {
    link: '/bridge/core-nursing/q1',
  },
  BRIDGE_CORE_NURSING_Q2: {
    link: '/bridge/core-nursing/q2',
  },
  BRIDGE_CORE_NURSING_INFO: {
    link: '/bridge/core-nursing/info',
  },
  BRIDGE_CORE_NURSING_END: {
    link: '/bridge/core-nursing/end',
  },
  BRIDGE_CORE_NURSING_PASSED_END: {
    link: '/bridge/core-nursing/passed-end',
  },
  BRIDGE_EXIT_EXAM: {
    link: '/bridge/exit-exam',
  },
  BRIDGE_EXIT_EXAM_Q1: {
    link: '/bridge/exit-exam/q1',
  },
  BRIDGE_EXIT_EXAM_Q2: {
    link: '/bridge/exit-exam/q2',
  },
  BRIDGE_EXIT_EXAM_INFO: {
    link: '/bridge/exit-exam/info',
  },
  BRIDGE_EXIT_EXAM_END: {
    link: '/bridge/exit-exam/end',
  },
  BRIDGE_EXIT_EXAM_PASSED_END: {
    link: '/bridge/exit-exam/passed-end',
  },
  BRIDGE_EXIT_EXAM_PASSED_END_Q1: {
    link: '/bridge/exit-exam/passed-end/q1',
  },
  BRIDGE_EXIT_EXAM_PASSED_END_Q1_END: {
    link: '/bridge/exit-exam/passed-end/q1/end',
  },
  BRIDGE_CONFIRM_CONTACT: {
    link: '/bridge/confirm-contact',
  },
  BRIDGE_RESULTS: {
    link: '/bridge/results',
  },
  BRIDGE_PLAN_NEXT_STEP: {
    link: '/bridge-plan/next-step',
  },
  BRIDGE_PLAN_ADVISOR: {
    link: '/bridge-plan/advisor',
  },
  BRIDGE_PLAN_ADVISOR_PLAN: {
    link: '/bridge-plan/advisor-plan',
  },
  BRIDGE_PLAN_WHY_PLAN: {
    link: '/bridge-plan/why-plan',
  },
  BRIDGE_PLAN_PRE_BUY: {
    link: '/bridge-plan/pre-buy',
  },
  BRIDGE_PLAN_PRE_BUY_CHECKOUT: {
    link: '/bridge-plan/pre-buy/checkout',
  },
  BRIDGE_PLAN_WO_PRE_BUY_READY: {
    link: '/bridge-plan/wo-pre-buy/ready',
  },
  BRIDGE_PLAN_WO_PRE_BUY_READY_SOON_RES_SCHEDULED: {
    link: '/bridge-plan/wo-pre-buy/ready/soon/res-sched',
  },
  BRIDGE_PLAN_WO_PRE_BUY_READY_NOT_YET_VIDEO: {
    link: '/bridge-plan/wo-pre-buy/ready/not-yet/video',
  },

  BRIDGE_PLAN_WO_PRE_BUY_READY_NOT_YET_INFO: {
    link: '/bridge-plan/wo-pre-buy/ready/not-yet/info',
  },
  BRIDGE_PLAN_WO_PRE_BUY_READY_NOT_YET_RES_SCHEDULED: {
    link: '/bridge-plan/wo-pre-buy/ready/not-yet/res-sched',
  },

  BRIDGE_PLAN_PRE_BUY_PURCHASED: {
    link: '/bridge-plan/pre-buy/purchased',
  },

  BRIDGE_PLAN_PRE_BUY_RES: {
    link: '/bridge-plan/pre-buy/res',
  },

  BRIDGE_PLAN_PRE_BUY_RES_SCHEDULED: {
    link: '/bridge/pre-buy/res-sched',
  },

  BRIDGE_PLAN_WO_PRE_BUY_READY_IMMEDIATE_CONNECT: {
    link: '/bridge-plan/wo-pre-buy/ready/immediate/connect',
  },

  // BRIDGE_PLAN_WO_PRE_BUY_READY_IMMEDIATE_CHAT: {
  //   link: '/bridge-plan/wo-pre-buy/ready/immediate/chat',
  // },
  BRIDGE_PLAN_WO_PRE_BUY_READY_IMMEDIATE_INFO: {
    link: '/bridge-plan/wo-pre-buy/ready/immediate/info',
  },
  BRIDGE_PLAN_WO_PRE_BUY_READY_IMMEDIATE_RES_SCHEDULED: {
    link: '/bridge-plan/wo-pre-buy/ready/immediate/res-sched',
  },
  BRIDGE_PLAN_WO_PRE_BUY_READY_NOT_YET_OPTS: {
    link: '/bridge-plan/wo-pre-buy/ready/not-yet/opts',
  },

  REDIRECT_TO_MEET_NOW: {
    link: '/redirect-to-meet-now/:id',
    onlyLink: '/redirect-to-meet-now',
    isNotBp: true,
  },
  CONFIRMATION: {
    link: '/confirmation',
    isNotBp: true,
  },
  CHAT: {
    link: '/chat',
    isNotBp: true,
  },
};
